<template>
  <lenon-list-page
    title="Failed SMS Messages"
    :columns="columns"
    :rows="messages"
    :table-loading="tableLoading"
    show-search
    show-details
  >
    <template #action="{row}">
      <lenon-button
        icon="RefreshCwIcon"
       label="Resend"
        tool-tip-text="Resend Messages"
        variant="outline-primary"
        class="mr-1"
        @onClick="resendFailedSms(row.item.id)"
      />
      <lenon-button
          icon="XIcon"
          label="Delete"
          tool-tip-text="Delete Failed Message"
          variant="outline-danger"
          @onClick="deleteFailedSms(row.item.id)"
      />
    </template>
    <template #message="{row}">
      {{row.item.data.message}}
    </template>
    <template #phones="{ row }">
      <lenon-button
          icon-only
          variant="flat-primary"
          :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
          tool-tip-text="View phone numbers"
          @onClick="row.toggleDetails"
      />
    </template>
    <template #row-details="{ row }">
      <b-card>
        <div class="row">
          <div class="col-12">
            <strong>Phone Numbers</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div v-if="row.item.data.to" class="d-flex flex-wrap">
              <b-badge variant="light-success" v-for="(item,i) in row.item.data.to" :key="i">
                {{ item }}
              </b-badge>
            </div>
            <b-badge v-else variant="light-success">
              N/A
            </b-badge>
          </div>
        </div>
      </b-card>
    </template>
  </lenon-list-page>
</template>

<script>
import LenonListPage from '@/lenon/components/LenonListPage'
import LenonButton from '@/lenon/components/LenonButton'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import {BBadge} from 'bootstrap-vue'
export default {
  name: 'FailedSms',
  components: { LenonButton, LenonListPage,BBadge },
  mixins:[confirm,showToast],
  data() {
    return {
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'message',
          label: 'Message',
        },
        {
          key: 'action',
          label: 'Actions',
        },
        {
          key: 'phones',
          label: 'View Phones',
        },
      ],
      messages: [],
      tableLoading: false,
    }
  },
  methods:{
    getFailedSms(){
      this.tableLoading = true
      this.$http.get("accounts/failed-sms").then((res)=>{
        this.messages = res.data
      }).finally(()=>{
        this.tableLoading = false
      })
    },
    resendFailedSms(id){
      this.confirmAction("Are you sure you want to resend sms messages?").then((res)=>{
        if(res){
          this.tableLoading = true
          this.$http.post(`accounts/resend-failed-sms/${id}`).then((res)=>{
            this.showSuccess("Messages are being resent.")
          }).catch((err)=>{
            this.showError("Resent failed, please try again.")
          }).finally(()=>{
            this.tableLoading = false
          })
        }
      })
    },
    deleteFailedSms(id){
      this.confirmAction("Are you sure you want to delete failed sms message?").then((res)=>{
        if(res){
          this.tableLoading = true
          this.$http.delete(`accounts/delete-failed-sms/${id}`).then((res)=>{
            this.showSuccess("Deleted successfully.")
            this.messages = this.messages.filter((m)=>{
              return m.id !== id
            })
          }).catch((err)=>{
            this.showError("Failed, please try again.")
          }).finally(()=>{
            this.tableLoading = false
          })
        }
      })
    }
  },
  mounted() {
    this.getFailedSms()
  }
}
</script>

<style scoped>

</style>
